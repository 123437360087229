import React from 'react'
import { motion } from 'framer-motion'
import SideBarListItem from './SideBarListItem'
import AnimatedButton from '../../../AnimatedButton/AnimatedButton'

const SideBarList = ({
  toggleOpen,
  menuItems,
  bottomButton,
  globalTextStyle,
  listSpeed,
  buttonSpeed,
}) => {
  const listAnimation = {
    open: {
      transition: {
        staggerChildren: listSpeed?.openStagger ? listSpeed.openStagger : 0.07,
        delayChildren: listSpeed?.openDelay ? listSpeed.openDelay : 0.2,
      },
    },
    closed: {
      transition: {
        staggerChildren: listSpeed?.closeStagger ? listSpeed.closeStagger : 0.05,
        staggerDirection: -1,
      },
    },
  }

  return (
    <React.Fragment>
      <motion.ul variants={listAnimation} className="sidebar-ul">
        {menuItems.map((item, idx) => (
          <SideBarListItem
            toggleOpen={toggleOpen}
            item={item}
            key={item?.text ? item.text : idx}
            globalTextStyle={globalTextStyle}
          />
        ))}
      </motion.ul>
      <motion.div
        className="sidebar-bottom-button"
        style={bottomButton?.doNotRender ? { display: 'none' } : {}}
        variants={{
          open: { opacity: 1, transition: { delay: buttonSpeed ? buttonSpeed : 0.35 } },
          closed: { opacity: 0 },
        }}
        data-testid="bottom-button"
      >
        <AnimatedButton
          intensity={bottomButton?.intensity ? bottomButton.intensity : null}
          style={bottomButton?.style ? bottomButton.style : {}}
          className={
            bottomButton?.className
              ? bottomButton.className + ' sidebar-bottom-button'
              : 'sidebar-bottom-button'
          }
          onClick={bottomButton?.onClick ? bottomButton.onClick : null}
          disabled={bottomButton?.disabled ? bottomButton.disabled : false}
        >
          {bottomButton?.text ? bottomButton.text : 'Log Out'}
        </AnimatedButton>
      </motion.div>
    </React.Fragment>
  )
}

export default SideBarList
