import React, { useState } from 'react'
import AnimatedButton from '../AnimatedButton/AnimatedButton'
import api from '../../api'

const ClaimBalance = ({ ambassadorId, availableBalance, setModalState, updateUser }) => {
  const [loading, setLoading] = useState(false)
  const [claimed, setClaimed] = useState(false)

  return (
    <div>
      {claimed ? (
        <p>
          Your balance of ${availableBalance} has been claimed.
          <br />
          Please check your email for a link to collect your balance!
          <br />
          <br />
          We've reset your current balance back to $0.
          <br />
          If you have any issues, please send an email to support@collegepulse.com and
          dev-team@collegepulse.com
        </p>
      ) : (
        <p>
          You have <strong>${availableBalance}</strong> available to be claimed!
          <br />
          Click the button below to get emailed a link to collect your balance!
        </p>
      )}

      <hr />
      <AnimatedButton
        disabled={claimed || loading}
        className={loading ? 'loading' : ''}
        onClick={async () => {
          setLoading(true)
          await api.user.claimBalance()
          await updateUser()
          setLoading(false)
          setClaimed(true)
          // setTimeout(() => {
          //   setModalState({
          //     open: false,
          //     component: null,
          //     buttons: null,
          //     header: null,
          //   })
          // }, 3000)
        }}
      >
        {loading ? 'Loading...' : claimed ? 'Balance Claimed!' : 'Claim Balance'}
      </AnimatedButton>
    </div>
  )
}

export default ClaimBalance
