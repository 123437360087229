import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import './styles/bulma.scss'
//import 'semantic-ui-css/semantic.min.css'
import './styles/main.scss'
import './styles/home.scss'
import './styles/auth.scss'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
