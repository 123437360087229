const handleName = user => {
  if (user?.firstName && user?.lastName) {
    return { firstName: user.firstName, lastName: user.lastName }
  } else if (user?.firstName || user?.lastName) {
    return { firstName: user?.firstName || null, lastName: user?.lastName || null }
  } else if (user?.email) {
    return { firstName: user?.email?.split('@')[0], lastName: null }
  } else if (user?.institution?.name) {
    return { firstName: user.institution.name, lastName: 'Student' }
  } else {
    return { firstName: 'Student', lastName: null }
  }
}

export default handleName
