import store from 'store'
import cookie from 'js-cookie'
import expirePlugin from 'store/plugins/expire'
store.addPlugin(expirePlugin)

const isLocalStorage = () => {
  try {
    localStorage.setItem('test', 'test')
    localStorage.removeItem('test')
    return true
  } catch (e) {
    return false
  }
}

/**
 * Get localStroage, or cookies if localStorage is not available
 * @param {*} name - The name of the local storage or cookie
 *
 * @returns reeturns the value of local storage or cookies
 */
const get = name => {
  if (isLocalStorage()) {
    return store.get(name)
  } else {
    return cookie.getJSON(name)
  }
}

/**
 * Set localStroage, or cookies if localStorage is not available
 * @param {Object} options - The options for the localStorage or cookie
 * @param {String} name - The name of the cookie/localStorage
 * @param {Object} val - The value of the cookie/localStorage
 * @param {Boolean=} neverExpire - If true never expire, else expire in 30 years
 * @param {Number=} expirationInDays - If set, expire in this many days
 * @param {Number=} expirationInHours - If set, expire in this many hours
 * @returns {Boolean} - Always return true. If you want to check if the cookie was set, use get
 */
const set = ({
  name,
  val,
  neverExpire = false,
  expirationInDays = null,
  expirationInHours = null,
}) => {
  if (!name) {
    return false
  }

  if (!val && val !== 0) {
    return false
  }

  // 30 years!
  const thirtyYearsInDays = 365 * 30
  const thirtyYearsInMilli = 1000 * 60 * 60 * 24 * thirtyYearsInDays
  const dateLongTimeFromNow = neverExpire
    ? new Date().getTime() + thirtyYearsInMilli
    : 'doesnt matter'

  if (isLocalStorage()) {
    if (neverExpire) {
      store.set(name, val, dateLongTimeFromNow)
    } else if (expirationInDays) {
      const expirationInMilli = 1000 * 60 * 60 * 24 * expirationInDays
      store.set(name, val, new Date().getTime() + expirationInMilli)
    } else if (expirationInHours) {
      const expirationInMilli = 1000 * 60 * 60 * expirationInHours
      store.set(name, val, new Date().getTime() + expirationInMilli)
    } else {
      store.set(name, val)
    }
  } else {
    if (neverExpire) {
      cookie.set(name, val, { expires: thirtyYearsInDays })
    } else if (expirationInDays) {
      cookie.set(name, val, { expires: expirationInDays })
    } else {
      cookie.set(name, val)
    }
  }
  return true
}

const remove = name => {
  if (isLocalStorage()) {
    store.remove(name)
  } else {
    cookie.remove(name)
  }
}

const clear = () => {
  if (isLocalStorage()) {
    store.clearAll()
  } else {
    Object.keys(cookie.getJSON()).forEach(key => {
      cookie.remove(key)
    })
  }
  return true
}

const storageFunctions = { get, set, remove, clear }

export default storageFunctions
