import React, { useEffect, useContext, useState } from 'react'
import UserContext from '../../lib/UserContext'
import AmbassadorPortfolioCard from '../../components/AmbassadorPortfolioCard/AmbassadorPortfolioCard'
import handleName from '../../lib/handleName'

import {
  SurveyCard,
  // ClosedSurvey,
} from '../../components/SurveyCards'
import _ from 'lodash'
import api from '../../api'
import ClaimBalance from '../../components/claim-balance/ClaimBalance'
import { Modal } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import storage from '../../lib/storage'

const defaultModalState = {
  open: false,
  component: null,
  header: null,
}

// NOTE: We add the surveys to localStorage. And on initial load, show the surveys from localStorage
// In the meantime, get the surveys from the server on initial load and once loaded save them to localStorage
// and update the state with the surveys from localStorage. This will make the app faster and more responsive
// and will also reduce the number of requests to the server

const Home = ({ updateUser }) => {
  const expand = '➕'
  const minimize = '➖'
  const user = useContext(UserContext)

  // Due to the slow nature of loading the survey data, we will use the localStorage to get initial data
  // This will be updated once the server data is loaded
  const [availableSurveys, setAvailableSurveys] = useState(storage.get('availableSurveys') || [])
  const [fieldingSurveys, setFieldingSurveys] = useState(storage.get('fieldingSurveys') || [])
  const [closedSurveys, setClosedSurveys] = useState(storage.get('closedSurveys') || [])
  const [minimizeAvailableSurveys, setMinimizeAvailableSurveys] = useState(false)
  const [minimizeFieldingSurveys, setMinimizeFieldingSurveys] = useState(false)
  const [minimizeClosedSurveys, setMinimizeClosedSurveys] = useState(false)
  const [loadingSurveys, setLoadingSurveys] = useState(true)
  const [modalState, setModalState] = useState({
    open: false,
    component: null,
    header: null,
  })

  const joinSurvey = async surveyId => {
    let availableSurveysToStore = availableSurveys
    let fieldingSurveysToStore = fieldingSurveys
    // add the survey with surveyId to fielding
    if (fieldingSurveys && fieldingSurveys.length !== 0) {
      fieldingSurveysToStore = [
        ...fieldingSurveys,
        availableSurveys.find(survey => survey.id === surveyId),
      ]
    } else {
      fieldingSurveysToStore = [availableSurveys.find(survey => survey.id === surveyId)]
    }
    // remove the survey with surveyId from availableSurveys
    availableSurveysToStore = availableSurveys?.filter(survey => survey.id !== surveyId)

    setFieldingSurveys(fieldingSurveysToStore)
    setAvailableSurveys(availableSurveysToStore)

    // Set the surveys and user to localStorage in case user refreshes the page or navigates away
    storage.set({ name: 'fieldingSurveys', val: fieldingSurveysToStore })
    storage.set({ name: 'availableSurveys', val: availableSurveysToStore })

    user.ambassadorConfig.surveys.push({ respondents: [], _q_survey_id: surveyId })
    storage.set({ name: 'user', val: user })

    // Do a proper update on the server, update the user and the surveys
    // this will trigger a re-render and the user will see the updated surveys
    await api.survey.joinSurvey(surveyId)
    await updateUser()
  }

  useEffect(() => {
    setUpSurveys(
      user?._q_panelist_id,
      user?.ambassadorConfig?.surveys,
      setFieldingSurveys,
      setAvailableSurveys,
      setLoadingSurveys,
      setClosedSurveys,
    )
  }, [user?._q_panelist_id, user?.ambassadorConfig?.surveys])

  return (
    <>
      <Modal
        closeIcon={{ style: { top: '1rem', right: '1rem' }, color: 'black', name: 'close' }}
        open={modalState.open}
        onClose={() => {
          setModalState(defaultModalState)
        }}
        centered
        style={{
          position: 'relative',
        }}
        dimmer="blurring"
      >
        <Modal.Content
          style={{
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          {modalState.header && (
            <Modal.Header>
              <h4>{modalState.header}</h4>
              <hr />
            </Modal.Header>
          )}

          <Modal.Description>
            {modalState.component ? modalState.component() : 'Nothing to show.'}
          </Modal.Description>
        </Modal.Content>
      </Modal>

      <div className="main-container">
        <div className="main-container-left" style={{ flex: 1, paddingRight: '1rem' }}>
          {fieldingSurveys && !_.isEmpty(fieldingSurveys) && (
            <div className="card mb-4 has-background-light">
              <div
                className="card-header p-4 content"
                onClick={() => setMinimizeFieldingSurveys(!minimizeFieldingSurveys)}
              >
                <h4 className="survey-container-header mb-0">
                  <span>Surveys in the field</span>{' '}
                  <span>{minimizeFieldingSurveys ? expand : minimize}</span>
                </h4>
              </div>

              {!minimizeFieldingSurveys && (
                <div className="card-content pl-4 pr-4 pt-4 pb-1">
                  {fieldingSurveys?.map(survey => (
                    <SurveyCard
                      type={'fielding'}
                      key={survey.id}
                      survey={survey}
                      user={user}
                      setModalState={setModalState}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
          <div className="card mb-4 has-background-light">
            <div
              className="card-header p-4 content"
              onClick={() => setMinimizeAvailableSurveys(!minimizeAvailableSurveys)}
            >
              <h4 className="survey-container-header mb-0">
                <span>Available Surveys</span>
                <span>{minimizeAvailableSurveys ? expand : minimize}</span>
              </h4>
            </div>

            {(!minimizeAvailableSurveys && (
              <div className="card-content pl-4 pr-4 pt-4 pb-1">
                {availableSurveys && !_.isEmpty(availableSurveys) ? (
                  availableSurveys?.map(survey => (
                    <SurveyCard
                      type={'available'}
                      key={survey.id}
                      updateUser={updateUser}
                      user={user}
                      survey={survey}
                      joinSurvey={joinSurvey}
                    />
                  ))
                ) : loadingSurveys ? (
                  <div className="survey-message-box">
                    <p className="loading">
                      We are currently loading the available surveys for you, in peak times this may
                      take up to a minute.
                      <br />
                      Please wait...
                    </p>
                  </div>
                ) : (
                  <div className="survey-message-box">
                    <p>
                      We're sorry, there are currently no available surveys for you to field.
                      <br />
                      Please check again later...
                    </p>
                  </div>
                )}
              </div>
            )) ||
              null}
          </div>
          {closedSurveys && !_.isEmpty(closedSurveys) && (
            <div className="card mb-4 has-background-light">
              <div
                className="card-header p-4 content"
                onClick={() => setMinimizeClosedSurveys(!minimizeClosedSurveys)}
              >
                <h4 className="survey-container-header mb-0">
                  <span>Closed Surveys</span>
                  <span>{minimizeClosedSurveys ? expand : minimize}</span>
                </h4>
              </div>
              {!minimizeClosedSurveys && (
                <div className="card-content p-4">
                  {closedSurveys.map(closedSurvey => (
                    <SurveyCard
                      type={'closed'}
                      key={closedSurvey._id}
                      survey={closedSurvey}
                      user={user}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="main-container-right" style={{ width: 'auto' }}>
          <AmbassadorPortfolioCard
            ambassador={{
              metadata: {
                ...handleName(user),
                institution: user?.institution_name,
                createdAt:
                  new Date(user?.ambassadorConfig?.createdAt)?.toLocaleDateString('default', {
                    month: 'long',
                    year: 'numeric',
                  }) || '',
              },
              data: {
                'Survey Participated:': user?.ambassadorConfig?.surveys?.length || 0,
                'Completes Generated:':
                  user?.ambassadorConfig?.surveys?.reduce(
                    (acc, cur) => acc + cur.respondents.length,
                    0,
                  ) || 0,
                'Total Income:': `$${_.sumBy(user?.ambassadorConfig?.payouts, 'amount') || 0}`,
                'Available to Cash Out:': `$${user?.ambassadorConfig?.balance || 0}`,
              },
            }}
            cardButton={{
              text: 'Cash Out',
              onClick: () => {
                setModalState({
                  header: 'Claim Current Balance',
                  buttons: null,
                  open: true,
                  component: () => {
                    return (
                      <ClaimBalance
                        ambassadorId={user._id}
                        availableBalance={user?.ambassadorConfig?.balance || 0}
                        setModalState={setModalState}
                        updateUser={updateUser}
                      />
                    )
                  },
                })
              },
              disabled: +user?.ambassadorConfig?.balance <= 0,
            }}
          />
        </div>
      </div>
    </>
  )
}

/**
 * Set up the surveys for the ambassador
 *
 * @param {String} userId The Qualtrics panelist id
 * @param {Object[]} ambassadorSurveys An array of surveys that the ambassador is fielding
 * @param {Callback} setFieldingSurveys A function to set the surveys that the ambassador is fielding
 * @param {Callback} setAvailableSurveys A function to set the available surveys for the ambassasdor
 * @param {Callback} setLoadingSurveys A function to set the loading state of the surveys
 * @param {Callback} setClosedSurveys A function to set the closed surveys for the ambassador
 *
 * @returns {Promise<void>}
 */
const setUpSurveys = async (
  userId,
  ambassadorSurveys,
  setFieldingSurveys,
  setAvailableSurveys,
  setLoadingSurveys,
  setClosedSurveys,
) => {
  console.log('GETTING SURVEYS!!!')
  const surveys = await api.survey.getAvailableSurveys(userId)

  if (ambassadorSurveys && surveys) {
    // NOTE: This might be a bit slow??? Maybe we can optimize this later
    const { fieldingSurveys, availableSurveys, closedSurveys } = surveys.reduce(
      (acc, survey) => {
        if (ambassadorSurveys.find(s => s._q_survey_id === survey.id)) {
          if (!acc.fieldingSurveys) acc.fieldingSurveys = []
          if (survey?.incentiveData?.incentiveResponses >= survey?.incentiveData?.responseLimit) {
            if (!acc.closedSurveys) acc.closedSurveys = []
            acc.closedSurveys.push(survey)
          } else {
            acc.fieldingSurveys.push(survey)
          }
        } else if (
          survey?.incentiveData?.incentiveResponses < survey?.incentiveData?.responseLimit
        ) {
          if (!acc.availableSurveys) acc.availableSurveys = []
          acc.availableSurveys.push(survey)
        }

        return acc
      },
      { fieldingSurveys: [], availableSurveys: [], closedSurveys: [] },
    )

    setFieldingSurveys(fieldingSurveys || [])
    storage.set({ name: 'fieldingSurveys', val: fieldingSurveys || [] })
    setAvailableSurveys(availableSurveys)
    storage.set({ name: 'availableSurveys', val: availableSurveys || [] })
    setClosedSurveys(closedSurveys || [])
    storage.set({ name: 'closedSurveys', val: closedSurveys || [] })

    setLoadingSurveys(false)
    console.log('DONE GETTING SURVEYS!!!')
  } else {
    console.warn('WARNING: No surveys to set up!')
  }
}

export default Home
