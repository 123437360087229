import * as React from 'react'
import { useRef } from 'react'
import { motion } from 'framer-motion'

import useWindowDimensions from './useDimensions'
import SideBarList from './SideBarList'
import './SideBar.scss'

const SideBar = ({
  isOpen,
  toggleOpen,
  sideBar,
  sticky,
  backgroundSpeed,
  listSpeed,
  buttonSpeed,
  demo,
}) => {
  // gets the height of the background element dynamically, needed for animation
  const { height, width } = useWindowDimensions()
  const isMobile = width < 769 ? true : false
  const sidebarWidth = isMobile ? width : sideBar?.width ? sideBar.width : 300
  const containerRef = useRef(null)
  // deals with animation of the background
  const sidebarAnimation = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
      transition: {
        delay: backgroundSpeed?.openDelay ? backgroundSpeed.openDelay : 0,
        type: 'spring',
        stiffness: backgroundSpeed?.openStiffness ? backgroundSpeed.openStiffness : 20,
        restDelta: 2,
      },
    }),
    closed: {
      clipPath: `circle(0px at ${sidebarWidth - 40}px 40px)`,
      transition: {
        delay: backgroundSpeed?.closeDelay ? backgroundSpeed.closeDelay : 0.5,
        type: 'spring',
        stiffness: backgroundSpeed?.closeStiffness ? backgroundSpeed.closeStiffness : 400,
        damping: 40,
      },
    },
  }

  return (
    <motion.div
      style={demo ? { width: `${sidebarWidth}px`, height: demo } : { width: `${sidebarWidth}px` }}
      className={`
      ${sticky ? 'pulse-sidebar-sticky' : 'pulse-sidebar'} 
      ${!isOpen ? 'sidebar-closed' : 'sidebar-open'}`}
      animate={isOpen ? 'open' : 'closed'}
      initial={false}
      custom={height}
      ref={containerRef}
      data-testid="sidebar"
    >
      <motion.div
        style={
          sideBar?.style
            ? { ...{ width: `${sidebarWidth}px` }, ...sideBar?.style }
            : { width: `${sidebarWidth}px` }
        }
        className={`sidebar-background ${sideBar?.className}`}
        variants={sidebarAnimation}
      />
      <SideBarList
        toggleOpen={toggleOpen}
        menuItems={sideBar?.menuItems ? sideBar.menuItems : []}
        bottomButton={sideBar?.bottomButton ? sideBar.bottomButton : null}
        globalTextStyle={sideBar?.globalTextStyle ? sideBar.globalTextStyle : null}
        listSpeed={listSpeed}
        buttonSpeed={buttonSpeed}
      />
    </motion.div>
  )
}

export default SideBar
