import React from 'react'
import { motion } from 'framer-motion'
import './bulma.scss'

const AnimatedButton = ({ children, intensity, className, style, onClick, disabled }) => {
  return (
    <motion.button
      style={style}
      className={`${className} button is-success`}
      whileHover={!disabled ? { scale: 1.1 + (!intensity ? 0 : intensity) } : 1}
      whileTap={!disabled ? { scale: 0.9 - (!intensity ? 0 : intensity) } : 1}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </motion.button>
  )
}

AnimatedButton.defaultProps = {
  intensity: 0,
  className: '',
  style: {},
  onClick: null,
  disabled: false,
}

export default AnimatedButton
