import PulseNavBar from './composition/NavBar'
import api from '../../api'
import './NavBar.scss'
import { useNavigate } from 'react-router-dom'

const NavBar = () => {
  const navigate = useNavigate()
  return (
    <PulseNavBar
      navBar={{
        sticky: true,
        logo: '/assets/CP_Ambassador.svg',
      }}
      sideBar={{
        menuItems: [
          {
            text: 'Ambassador Dashboard',
            // href: '/dashboard',
            onClick: () => {
              navigate('/dashboard')
            },
          },
          {
            text: 'College Pulse Website',
            href: 'https://collegepulse.com/',
          },
          {
            text: 'Resources & FAQ',
            // href: '/dashboard/resources',
            onClick: () => {
              navigate('/dashboard/resources')
            },
          },
          // TODO: Create passowrd reset page and logic...
          {
            text: 'Change Password',
            // href: '/dashboard/change-password',
            onClick: () => {
              navigate('/dashboard/change-password')
            },
          },
        ],
        bottomButton: {
          text: 'Log Out',
          onClick: () => {
            api.auth.signout()
          },
          style: {
            width: '100%',
            justifyContent: 'center',
          },
        },
      }}
    />
  )
}

export default NavBar
