import React from 'react'
import { saveAs } from 'file-saver'
import { motion } from 'framer-motion'

const Resources = () => {
  return (
    <div className="content pt-6 resources">
      <TitleBar title="Welcome!" />
      <p>
        Congrats! You’re officially a College Pulse Student Ambassador. We’re excited to have you
        join our team of ambassadors on college campuses across the United States!
        <br />
        <br />
        As an ambassador you’ll share surveys with students on your campus using your survey share
        link. Then, students will click the link, take the survey, and receive a reward that can be
        redeemed at 700+ stores and charities. Once the student completes the survey, you’ll earn
        cash. The more you share, the more you earn.
        <br />
        <br />
        To get you started, here is some important info and frequently asked questions.
      </p>

      <TitleBar title="Guide" />
      <div className="section">
        <ol style={{ margin: '1em' }}>
          <li>Join a survey </li>
          <p>
            You can easily join a surveys as long as it is available for fielding in your school.
            <b />
            <a href={` ${window.location.pathname}/more-info`}> More info</a>
          </p>
          <li>Find your survey link </li>
          <p>
            Each survey has a different link unique to your profile, you can find the survey link in
            your ambassador dashboard.
            <b />
            <a href={` ${window.location.pathname}/more-info#findLink`}> More info</a>
          </p>
          <li>Identify the student reward</li>
          <p>
            Students who complete a survey will receive a reward redeemable at 700+ stores and
            charities. You can find the student reward amount in your ambassador dashboard.
            <a href={`${window.location.pathname}/more-info#ambassador-card`}> More info</a>
          </p>
          <li>Share your link!</li>
          <p>
            Start sharing your link! Most ambassadors share their link through email, texts, Canvas
            messaging, and social media, but feel free to get creative, and share it however you
            think students will best receive it on your campus.
            <a href={`${window.location.pathname}/more-info#findLink`}> More info</a>
          </p>
          <li>Identify the ambassador reward</li>
          <p>
            You’ll receive a predetermined amount for each student who completes the survey through
            your link. This amount will be listed in your ambassador dashboard.
            <a href={`${window.location.pathname}/more-info#ambassador-card`}> More info</a>
          </p>
          <li>Get Paid</li>
          <p>
            Once students have completed the survey through your link, your earnings will be updated
            in your ambassador dashboard. You can then cash out your earnings.
            <a href={`${window.location.pathname}/more-info#payout-info`}> More info</a>
          </p>
        </ol>
        Optional: Follow College Pulse on{' '}
        <a href="https://www.instagram.com/college_pulse/" target="_blank" rel="noreferrer">
          Instagram
        </a>
        ,{' '}
        <a
          href="https://twitter.com/CollegeInsights?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
          target="_blank"
          rel="noreferrer"
        >
          Twitter
        </a>
        , and{' '}
        <a href="https://www.linkedin.com/company/college-pulse" target="_blank" rel="noreferrer">
          Linkedin
        </a>
        !
      </div>

      <TitleBar title="Frequently Asked Questions" />
      <div className="section">
        <ul style={{ margin: '1em' }}>
          <li>Q: What devices can students take the survey on?</li>
          <p>
            A: Students can take the survey on any internet-enabled device. That includes laptops,
            cellphones, & tablets.
          </p>
          <li>Q: Identify the student reward</li>
          <p>
            A: You can find the total number of students who have taken a particular survey through
            your link in your ambassador dashboard.
          </p>
          <li>Q: Are there certain posters or Instagram posts I should share?</li>
          <p>
            A: You can find posters, social media templates, and basic copy options on the
            Ambassador Resources page. However, you are not required to use those. Feel free to get
            creative!
          </p>
          <li>Q: How will I find out about future surveys?</li>
          <p>
            A: You’ll receive a notification via email when a future survey becomes available for
            you to share. You can also find current surveys to share in your ambassador dashboard.
          </p>
          <li>Q: Who should I contact if I have any questions?</li>
          <p>
            A: Send an email to{' '}
            <a href="mailto:jaxon.smith@collegepulse.com">jaxon.smith@collegepulse.com</a> with any
            questions you have.
          </p>
        </ul>
      </div>

      <TitleBar title="Posters" />
      <div className="image-grid">
        <DownloadImage url="/assets/posters/Share Your Opinion On Poster (1).png" />

        <DownloadImage url="/assets/posters/Take Surveys Get Paid (2).png" />
      </div>

      <TitleBar title="Instagram Stories" />
      <div className="image-grid">
        <DownloadImage url="/assets/ig_stories/Click the Link In Bio.png" />

        <DownloadImage url="/assets/ig_stories/Share Your Opinion Story.png" />

        <DownloadImage url="/assets/ig_stories/Share Your Opinion w_Swipe Up Story.png" />

        <DownloadImage url="/assets/ig_stories/Take Surveys Story wSwipeUp.png" />

        <DownloadImage url="/assets/ig_stories/Take Surveys Story.png" />
      </div>

      <TitleBar title="Instagram Posts" />
      <div className="image-grid">
        <DownloadImage url="/assets/ig_posts/Click Link In My Bio IG Post.png" />

        <DownloadImage url="/assets/ig_posts/Share Your Opinion IG Post.png" />

        <DownloadImage url="/assets/ig_posts/Take Surveys IG Post.png" />
      </div>
    </div>
  )
}

const DownloadImage = ({ url }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={() => {
        saveAs(url, url.split('/').reverse()[0])
      }}
    >
      <img src={url} alt="download" />
    </motion.div>
  )
}

const TitleBar = ({ title }) => {
  return (
    <div className="mt-6 is-relative is-flex is-justify-content-center">
      <h5
        style={{
          zIndex: 2,
          backgroundColor: 'white',
          display: 'block',
          padding: '1rem',
          position: 'relative',
          width: 'fit-content',
        }}
      >
        {title}
      </h5>
      <hr
        style={{
          position: 'absolute',
          left: 0,
          top: '.1em',
          width: '100%',
          zIndex: 1,
          backgroundColor: '#00000050',
        }}
      />
    </div>
  )
}

export default Resources
