import React from 'react'
import SideBarBurgerToggle from '../SideBar/SideBarBurgerToggle'
import './NavBarHeader.scss'

import defaultLogo from './logo.svg'

export const NavBarHeader = ({ navBar, isOpen, toggleOpen }) => {
  return (
    <nav
      style={navBar?.style ? navBar.style : {}}
      className={`${navBar?.sticky ? 'pulse-navbar-sticky' : 'pulse-navbar'}
        ${navBar?.className ? navBar.className : ''}`}
      data-testid="navbar-header"
    >
      <div className="navbar-inside-box">
        <div className="navbar-logo-box horizontal-align">
          <img className="navbar-logo" src={navBar?.logo ? navBar.logo : defaultLogo} alt="LOGO" />
        </div>
        <div className="horizontal-align">
          <SideBarBurgerToggle
            toggle={() => toggleOpen()}
            animate={isOpen ? 'open' : 'closed'}
            burgerColor={navBar?.burgerColor ? navBar.burgerColor : ''}
          />
        </div>
      </div>
    </nav>
  )
}
