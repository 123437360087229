import request from './request'
import prefix from './prefix'
import { signout } from './auth'

/**
 * Get a list of surveys available to the ambassador
 *
 * @returns {Promise} - resolves with the list of surveys available to the ambassador
 */
export const getAvailableSurveys = () => {
  return new Promise((resolve, reject) => {
    try {
      request.get(prefix + '/api/ambassador/available-surveys').end((err, res) => {
        if (err) reject(err)
        if (res?.body?.status === 401) return signout()
        if (res?.body?.status !== 200) return reject(res?.body?.message)
        resolve(res.body.data)
      })
    } catch (err) {
      reject(err)
    }
  })
}

/**
 * Join a survey to the ambassador's list of surveys they are fielding
 *
 * @param {String} surveyId The Qualtrics survey ID to be joined by the ambassador
 *
 * @returns {Promise} resolves with the user's updated object
 */
export const joinSurvey = surveyId => {
  return new Promise((resolve, reject) => {
    try {
      request.get(prefix + `/api/ambassador/join-survey/${surveyId}`).end((err, res) => {
        if (err) reject(err)
        if (res?.body?.status === 401) return signout()
        if (res?.body?.status !== 200) return reject(res?.body?.message)

        resolve(res.body.data)
      })
    } catch (err) {
      reject(err)
    }
  })
}
