import React from 'react'
import { motion } from 'framer-motion'

const listItemAnimation = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const SideBarListItem = ({ toggleOpen, item, globalTextStyle }) => {
  return (
    <motion.li
      style={item.textStyle ? item.textStyle : globalTextStyle ? globalTextStyle : {}}
      className={`sidebar-li ${item.textClassName ? item.textClassName : ''}`}
      variants={listItemAnimation}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <div className={'text-placeholder'}>
        {item.href ? (
          <a
            href={item.href}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              display: 'block',
              padding: '1.25rem 0',
            }}
          >
            {item.text}
          </a>
        ) : (
          <div
            onClick={() => {
              item.onClick()
              toggleOpen()
            }}
            style={{ padding: '1.25rem 0' }}
          >
            {item.text}
          </div>
        )}
      </div>
    </motion.li>
  )
}
export default SideBarListItem
