import React, { useEffect } from 'react'

const ResourcesMoreInfo = () => {
  useEffect(() => {
    const currentId = '#' + (window.location.href.split('#')?.[1] || 'root')

    document.querySelector(currentId).scrollIntoView()
  })

  return (
    <div className="content pt-6 resources">
      <TitleBar title="Joining a Survey" titleID={'join-survey'} />
      <p>
        You can easily join a survey as long as it is available for fielding in your school. From
        any of the Available Surveys press <b>Join Survey</b>. Once you join a survey, it will move
        from the <b>Available Surveys</b> category to <b>Surveys in the Field</b>.
      </p>
      <p>
        <img
          src={'/assets/guide_images/availableSurveys.png'}
          className="mr-5"
          alt="survey card"
          style={{ maxWidth: '100%', objectFit: 'contain', objectPosition: 'top', margin: 'auto' }}
        />
      </p>
      <TitleBar title="Find Survey Link" titleID="findLink" />
      <div className="section">
        Each survey has a unique link to your profile that you can use to field the surveys. You can
        find the survey link on the survey card in your ambassador dashboard.
        <ol style={{ margin: '1em' }}>
          <p>
            <li>Get Link</li>
          </p>
          <p>
            On the survey click the <b>Get Link</b> button.
          </p>
          <p>
            <img
              src={'/assets/guide_images/getLink.png'}
              className="mr-5"
              alt="survey card"
              style={{
                maxWidth: '100%',
                objectFit: 'contain',
                objectPosition: 'top',
                margin: 'auto',
              }}
            />
          </p>
          <p>
            <li>Copy Link</li>
          </p>
          <p>
            You will see a pop-up on the screen. From there get the link by clicking the{' '}
            <b>Copy Link</b> button.
          </p>
          <p>
            <img
              src={'/assets/guide_images/copyLink.png'}
              className="mr-5"
              alt="survey card"
              style={{
                maxWidth: '100%',
                objectFit: 'contain',
                objectPosition: 'top',
                margin: 'auto',
              }}
            />
          </p>
          <p>
            <li>Share the link with your classmates.</li>
          </p>
        </ol>
      </div>
      <TitleBar title="Ambassador Card" titleID={'ambassador-card'} />
      <div className="section">
        Card Variables:
        <ul style={{ margin: '1em' }}>
          <li>
            <b>Name</b> - is the ambassadors name or if not present the first part of your email.
          </li>
          <li>
            <b>Active Since</b> - the date you joined College Pulse.
          </li>
          <li>
            <b>Institution Name</b> - the name of your school.
          </li>
          <li>
            <b>Survey Participated</b> - the amount of surveys you have joined and helped College
            Pulse field/complete.
          </li>
          <li>
            <b>Completes Generated</b> - the number of students you got to complete our surveys.
          </li>
          <li>
            <b>Total Income</b> - the total income you have earned since you became an ambassador.
          </li>
          <li>
            <b>Available to Cash Out</b> - the amount of money you have available to cash out at the
            moment.
          </li>
          <li>
            <b>Cash Out</b> - the button that starts the payout process.
          </li>
        </ul>
        <b />
        <img
          src={'/assets/guide_images/ambassadorCard.png'}
          className="mr-5"
          alt="survey card"
          style={{
            maxWidth: '100%',
            objectFit: 'contain',
            objectPosition: 'top',
            margin: 'auto',
          }}
        />
      </div>
      <TitleBar title="Payout" titleID={'payout-info'} />
      <div className="section">
        To initiate the payment process you must have a <b>non-zero balance</b>.
        <ol style={{ margin: '1em' }}>
          <p>
            <li>
              Start the payment by clicking on the <b>Cash Out</b> button inside of the ambassador
              card. There will be a pop up that will ask to Claim your Current Balance. Press{' '}
              <b>Claim Balance</b>.
            </li>
          </p>
          <p>
            <img
              src={'/assets/guide_images/pressCashOut.png'}
              className="mr-5"
              alt="survey card"
              style={{
                maxWidth: '100%',
                objectFit: 'contain',
                objectPosition: 'top',
                margin: 'auto',
              }}
            />
          </p>
          <div className="section">
            <li>
              <p>
                At this point you should have received an email that looks something like this.
                Click on <b>View Gift Reward</b>.
              </p>
              <p>
                <img
                  src={'/assets/guide_images/claimEmail.png'}
                  className="mr-5"
                  alt="survey card"
                  style={{
                    maxWidth: '100%',
                    objectFit: 'contain',
                    objectPosition: 'top',
                    margin: 'auto',
                  }}
                />
              </p>
              <p>
                This will lead you to a new page that looks like this. Click on <b>REDEEM</b>
              </p>
              <p>
                <img
                  src={'/assets/guide_images/redemptionStep1.png'}
                  className="mr-5"
                  alt="survey card"
                  style={{
                    maxWidth: '100%',
                    objectFit: 'contain',
                    objectPosition: 'top',
                    margin: 'auto',
                  }}
                />
              </p>
              <p>
                Now you can <b>Select Payment Method</b> currently we support:
              </p>
              <div className="section">
                <ul>
                  <li>Visa Gift Cards</li>
                  <li>Amazon Gift Cards</li>
                  <li>Target Gift Cards</li>
                  <li>PayPal</li>
                  <li>And more...</li>
                </ul>
              </div>
            </li>
          </div>
          <div className="section">
            <li>
              <p>
                Claiming Funds Exapmle: <b>Amazon Gift Card</b>
              </p>
              <p>
                <img
                  src={'/assets/guide_images/redemptionStep2.png'}
                  className="mr-5"
                  alt="survey card"
                  style={{
                    maxWidth: '100%',
                    objectFit: 'contain',
                    objectPosition: 'top',
                    margin: 'auto',
                  }}
                />
              </p>
              <p>
                Once you select Amazon, you will see a window looking like this. Here you confirm
                that you want to select <b>Amazon Gift Card</b> as your Payout Method.
              </p>
              <p>
                At this point you will be send back to our ambassador page, and you will receive a
                new email containing the gift card. To get yout Amazon Gift Card code, click on{' '}
                <b>View Gift Card</b> inside of the email you recieved.
              </p>
              <p>
                <img
                  src={'/assets/guide_images/claimGiftcard.png'}
                  className="mr-5"
                  alt="survey card"
                  style={{
                    maxWidth: '100%',
                    objectFit: 'contain',
                    objectPosition: 'top',
                    margin: 'auto',
                  }}
                />
              </p>
              <p>
                This will open a page where you will be able to claim your gift card code. Click on{' '}
                <b>View Code</b>, which will direct you to a page that holds all the information for
                your gift card, including the code.
              </p>
              <p>
                <img
                  src={'/assets/guide_images/claim-1.png'}
                  className="mr-5"
                  alt="survey card"
                  style={{
                    maxWidth: '100%',
                    objectFit: 'contain',
                    objectPosition: 'top',
                    margin: 'auto',
                  }}
                />
              </p>
              <p>Gift Card Claim Code:</p>
              <p>
                <img
                  src={'/assets/guide_images/claim-2.png'}
                  className="mr-5"
                  alt="survey card"
                  style={{
                    maxWidth: '100%',
                    objectFit: 'contain',
                    objectPosition: 'top',
                    margin: 'auto',
                  }}
                />
              </p>
              <p>
                To claim your gift card, go to:{' '}
                <a href="https://www.amazon.com/redeem/" target="_blank" rel="noopener noreferrer">
                  www.amazon.com/redeem
                </a>
                , and input your code.
              </p>
              <p>
                <img
                  src={'/assets/guide_images/amazon-1.png'}
                  className="mr-5"
                  alt="survey card"
                  style={{
                    maxWidth: '100%',
                    objectFit: 'contain',
                    objectPosition: 'top',
                    margin: 'auto',
                  }}
                />
              </p>
              <p>Your balance should have increased with the newly added funds. </p>
              <p>
                <img
                  src={'/assets/guide_images/amazon-2.png'}
                  className="mr-5"
                  alt="survey card"
                  style={{
                    maxWidth: '100%',
                    objectFit: 'contain',
                    objectPosition: 'top',
                    margin: 'auto',
                  }}
                />
              </p>
            </li>
          </div>
          <p> Thank you for assisting with our survey fielding process!</p>
        </ol>
      </div>
    </div>
  )
}

const TitleBar = ({ title, titleID }) => {
  return (
    <div className="mt-6 is-relative is-flex is-justify-content-center" id={titleID}>
      <h5
        style={{
          zIndex: 2,
          backgroundColor: 'white',
          display: 'block',
          padding: '1rem',
          position: 'relative',
          width: 'fit-content',
        }}
      >
        {title}
      </h5>
      <hr
        style={{
          position: 'absolute',
          left: 0,
          top: '.1em',
          width: '100%',
          zIndex: 1,
          backgroundColor: '#00000050',
        }}
      />
    </div>
  )
}

export default ResourcesMoreInfo
