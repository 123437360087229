import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import AnimatedButton from '../AnimatedButton/AnimatedButton'

const LinkBox = ({ surveyLink }) => {
  const [coppied, setCoppied] = useState(false)

  return (
    <CopyToClipboard
      text={surveyLink}
      onCopy={() => {
        setCoppied(true)
        setTimeout(() => {
          setCoppied(false)
        }, 5000)
      }}
    >
      <AnimatedButton style={{ fontWeight: '600' }}>
        {coppied ? 'Copied!' : 'Copy Link'}
      </AnimatedButton>
    </CopyToClipboard>
  )
}

export default LinkBox
