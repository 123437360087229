import React from 'react'
import { useCycle } from 'framer-motion'
import SideBar from './SideBar/SideBar'
import { NavBarHeader } from './NavBarHeader/NavBarHeader'
import './bulma.scss'

const NavBar = ({ sideBar, navBar, speedConfig, demo }) => {
  // needed for toggling sidebar
  const [isOpen, toggleOpen] = useCycle(false, true)

  return (
    <React.Fragment>
      <NavBarHeader navBar={navBar} isOpen={isOpen} toggleOpen={toggleOpen} />
      <SideBar
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        sideBar={sideBar}
        sticky={navBar && navBar.sticky}
        demo={demo}
        listSpeed={speedConfig?.list}
        buttonSpeed={speedConfig?.buttonOpenDelay}
        backgroundSpeed={speedConfig?.background}
      />
    </React.Fragment>
  )
}

const menuItems = []

const bottomButton = {
  text: '',
  onClick: () => {},
  style: {},
  className: '',
}

const menuItem = {
  text: '',
  onClick: () => {},
  href: '',
  textClassName: '',
  textStyle: {},
}

const speedConfig = {
  background: {
    openDelay: 0,
    openStiffness: 20,
    closeDelay: 0.3,
    closeStiffness: 400,
  },
  list: {
    openDelay: 0.2,
    openStagger: 0.07,
    closeStagger: 0.05,
  },
  buttonOpenDelay: 0.35,
}

NavBar.defaultProps = {
  sideBar: {
    width: 300,
    className: '',
    globalTextStyle: {},
    bottomButton: bottomButton,
    menuItems: menuItems,
  },
  bottomButton: {
    text: '',
    onClick: () => {},
    style: {},
    className: '',
    disabled: false,
    doNotRender: false,
  },
  menuItems: [menuItem],
  menuItem: {
    text: '',
    onClick: () => {},
    href: '',
    textClassName: '',
    textStyle: {},
  },
  navBar: {
    style: {},
    className: '',
    burgerColor: '',
    sticky: false,
    logo: '',
  },
  speedConfig: speedConfig,
  demo: '',
}

export default NavBar
