import React from 'react'
import { motion } from 'framer-motion'
import { useMediaQuery } from 'react-responsive'

const Path = props => <motion.path strokeWidth="3" strokeLinecap="round" {...props} />

const SideBarBurgerToggle = ({ toggle, burgerColor, animate }) => {
  const isMobileOrTablet = useMediaQuery({ maxWidth: 1224 })
  const svgBoxDimensions = isMobileOrTablet ? 42 : 22

  const burgerLine = isMobileOrTablet
    ? {
      top: {
        closed: { d: 'M 3.82 4.77 L 38.18 4.77' },
        open: { d: 'M 5.73 31.5 L 32.45 4.77' },
      },
      middle: {
        closed: { opacity: 1, d: 'M 3.82 17.99 L 38.18 17.99' },
        open: { opacity: 0, d: 'M 3.82 17.99 L 38.18 17.99' },
      },
      bottom: {
        closed: { d: 'M 3.82 31.21 L 38.18 31.21' },
        open: { d: 'M 5.73 4.77 L 32.45 31.21' },
      },
    }
    : {
      top: {
        closed: { d: 'M 2 2.5 L 20 2.5' },
        open: { d: 'M 3 16.5 L 17 2.5' },
      },
      middle: {
        closed: { opacity: 1, d: 'M 2 9.423 L 20 9.423' },
        open: { opacity: 0, d: 'M 2 9.423 L 20 9.423' },
      },
      bottom: {
        closed: { d: 'M 2 16.346 L 20 16.346' },
        open: { d: 'M 3 2.5 L 17 16.346' },
      },
    }

  return (
    <motion.div animate={animate}>
      <button onClick={toggle} className="sidebar-toggle-button" data-testid="toggle-sidebar">
        <svg
          width={svgBoxDimensions}
          height={svgBoxDimensions}
          viewBox={`${'0 0 ' + svgBoxDimensions + ' ' + svgBoxDimensions}`}
          className="burger-svg"
        >
          <Path variants={burgerLine.top} stroke={`${burgerColor ? burgerColor : 'white'}`} />
          <Path
            // d="M 2 9.423 L 20 9.423"
            variants={burgerLine.middle}
            transition={{ duration: 0.1 }}
            stroke={`${burgerColor ? burgerColor : 'white'}`}
          />
          <Path variants={burgerLine.bottom} stroke={`${burgerColor ? burgerColor : 'white'}`} />
        </svg>
      </button>
    </motion.div>
  )
}

export default SideBarBurgerToggle
