import React from 'react'
import './Progressbar.scss'
import './bulma.scss'

const Progressbar = ({ currentNumber, maximumNumber, theme, config }) => {
  // handle if currentNumber or maxNumber undefined
  const currNumber = currentNumber ? currentNumber : 1
  const maxNumber = maximumNumber ? maximumNumber : 0
  // get progress and handle division by 0, if maxNum = 0 then set progress to 0
  let progressDecimal = maxNumber ? currNumber / maxNumber : 0
  const progress = Math.round(progressDecimal * 100)
  const completedColor = theme && theme.completed ? theme.completed : 'cadetblue'
  const remainingColor = theme && theme.remaining ? theme.remaining : '#eee'
  const textColor = theme && theme.text ? theme.text : 'black'
  const defaultText = `${currNumber}/${maxNumber} - ${progress}%`
  const defaultPercentage = `${progress}%`
  const progressText =
    config && config.customText
      ? config.customText
      : config && config.onlyPercentage
        ? defaultPercentage
        : defaultText

  return (
    <div
      className={`progress-bar ${config && config.square ? 'square' : ''}`}
      style={{
        height: `${config && config.progressBarHeight ? config.progressBarHeight : '1rem'}`,
        background: `linear-gradient(to right, ${completedColor} ${progress}%, ${remainingColor} ${progress}%)`,
      }}
      data-testid="progressbar"
    >
      <span
        className="inside-text"
        style={{
          color: textColor,
          fontSize: `${config && config.textHeight ? config.textHeight : '0.75rem'}`,
        }}
      >
        {progressText}
      </span>
    </div>
  )
}

Progressbar.defaultProps = {
  theme: {
    completed: '',
    remaining: '',
    text: '',
  },
  config: {
    square: false,
    customText: '',
    onlyPercentage: false,
    progressBarHeight: '',
    textHeight: '',
  },
}

export default Progressbar
