import React from 'react'
import AnimatedButton from '../AnimatedButton/AnimatedButton'

import './Footer.scss'

const Footer = () => {
  return (
    <section className="footer-container">
      <div className="container has-text-white">
        <div className="columns">
          <div className="column is-one-third main-column">
            <img
              className="has-margin-bottom-3"
              width="250"
              src="https://collegepulse.com/cp_logo_white.svg"
              alt="College Pulse Logo"
            />
            <div className="logo-subtext">©2024 College Pulse | All Rights Reserved</div>
          </div>
          <div className="column">
            <h4 className="has-text-weight-bold footer-title">RESOURCES</h4>
            <ul className="footer-link-list">
              <li>
                <a
                  href="https://www.collegepulse.com/#research"
                  className="has-text-weight-light has-text-light"
                >
                  Our Research
                </a>
              </li>
              <li>
                <a
                  href="https://www.collegepulse.com/blog/page/1"
                  className="has-text-weight-light has-text-light"
                >
                  Research Blog
                </a>
              </li>
              <li>
                <a
                  href="https://www.collegepulse.com/methodology"
                  className="has-text-weight-light has-text-light"
                >
                  Methodology
                </a>
              </li>
              <li>
                <a
                  className="has-text-weight-light has-text-light"
                  href="https://www.collegepulse.com/#contactus"
                >
                  Work With Us
                </a>
              </li>
            </ul>
          </div>
          <div className="column">
            <h4 className="has-text-weight-bold footer-title">ABOUT US</h4>
            <ul className="footer-link-list">
              <li>
                <a
                  href="https://www.collegepulse.com/about/"
                  className="has-text-weight-light has-text-light"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="https://www.collegepulse.com/careers/"
                  className="has-text-weight-light has-text-light"
                >
                  Careers
                </a>
              </li>
              <li>
                <a href="https://www.collegepulse.com/terms">
                  <div className="has-text-weight-light has-text-white">Terms & Conditions</div>
                </a>
              </li>
            </ul>
          </div>
          <div className="column">
            <h4 className="has-text-weight-bold footer-title">CONTACT</h4>
            <ul className="footer-link-list">
              <AnimatedButton
                onClick={() => (window.location.href = 'https://www.collegepulse.com/#contactus')}
              >
                Get In Touch
              </AnimatedButton>
            </ul>
            <div className="block social-media">
              <span>
                <a href="https://www.facebook.com/PulseSurvey/">
                  <img
                    className="has-margin-right-3"
                    width="25"
                    src="https://collegepulse.com/icons/facebook.svg"
                    alt="Facebook"
                  />
                </a>
              </span>
              <span>
                <a href="https://twitter.com/CollegeInsights">
                  <img
                    className="has-margin-right-3"
                    width="25"
                    src="https://collegepulse.com/icons/twitter.svg"
                    alt="Twitter"
                  />
                </a>
              </span>
              <span>
                <a href="https://www.instagram.com/college_pulse/">
                  <img
                    width="25"
                    src="https://collegepulse.com/icons/instagram.svg"
                    alt="Instagram"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
