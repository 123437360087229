import request from './request'
import storage from '../lib/storage'
import prefix from './prefix'

/******************************** exported ************************************/

export const signin = function (username, password) {
  if (!username) return Promise.reject('Email field is required')
  if (!password) return Promise.reject('Password field is required')

  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/ambassador/signin')
      .send({ email: username, password: password })
      .end((err, res) => {
        if (res?.body?.status !== 200) return reject(res?.body?.message)
        if (!res.body.user) return reject('Error signing in, no user returned')
        if (!res.body.token) return reject('Error signing in, no token returned')
        if (err) return reject(err)

        storage.set({ name: 'token', val: res.body.token, expirationInHours: 1 })
        storage.set({
          name: 'user',
          val: { ...res.body.user, authenticated: true },
          expirationInHours: 1,
        })

        if (isSignedIn()) {
          return resolve(res.body)
        } else {
          return reject('Error signing in')
        }
      })
  })
}

export const signout = function () {
  console.log('SIGNING OUT!!!')
  storage.remove('token')
  storage.remove('user')

  if (prefix === 'http://localhost:4444') {
    window.location = 'http://localhost:3003'
  } else {
    window.location = 'https://ambassador.collegepulse.com/'
  }
}

/**
 *
 * @param {String} currentPassword
 * @param {String} newPassword
 * @returns
 */
export const changePassword = function (currentPassword, newPassword) {
  if (!currentPassword) return Promise.reject('Current password field is required')
  if (!newPassword) return Promise.reject('New password field is required')
  currentPassword = encodeURIComponent(currentPassword)
  newPassword = encodeURIComponent(newPassword)

  return new Promise((resolve, reject) => {
    request
      .get(prefix + `/api/ambassador/change-password/${currentPassword}/${newPassword}`)
      .end((err, res) => {
        if (res?.body?.message.includes('Unauthorized')) return signout()
        if (res?.body?.status !== 200) return reject(res?.body?.message)
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

/**
 * Initiate password reset, sends email with reset code
 *
 * @param {String} email Email to send reset code to, the email of the ambassador
 * @returns
 */
export const inititatePasswordReset = function (email) {
  if (!email) return Promise.reject('Email field is required')
  email = encodeURIComponent(email)

  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/ambassador/initiate-password-reset/${email}`).end((err, res) => {
      if (res?.body?.status !== 200) return reject(res?.body?.message)
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}

export const resetPassword = function (email, passwordResetCode, newPassword) {
  if (!email) return Promise.reject('Email field is required')
  if (!passwordResetCode) return Promise.reject('Reset code field is required')
  if (!newPassword) return Promise.reject('New password field is required')

  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/ambassador/reset-password')
      .send({ email: email, code: passwordResetCode, password: newPassword })
      .end((err, res) => {
        if (res?.body?.status !== 200) return reject(res?.body?.message)
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

/********************************* local *************************************/

const isSignedIn = function () {
  const token = storage.get('token')

  const tokenExists = token !== undefined && token !== 'undefined'

  // if token exists return true
  if (tokenExists) {
    return true
  }

  return false
}
