import React, { useState, useEffect } from 'react'
import AnimatedButton from '../../components/AnimatedButton/AnimatedButton'
import UserContext from '../../lib/UserContext'
import AuthInputBox from '../../components/AuthInputBox'
import api from '../../api'

import { useNavigate } from 'react-router-dom'

const ChangePassword = () => {
  const user = React.useContext(UserContext)
  const navigate = useNavigate()

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [error, setError] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  })

  const handleCurrentPasswordChange = e => {
    setCurrentPassword(e.target.value)
  }
  const handleNewPasswordChange = e => {
    setNewPassword(e.target.value)
  }

  const handleConfirmNewPasswordChange = e => {
    setConfirmNewPassword(e.target.value)
  }

  useEffect(() => {
    if (!user?.authenticated) {
      navigate('/')
    }
  }, [user, navigate])

  useEffect(() => {
    if (confirmNewPassword && newPassword !== confirmNewPassword)
      setError({ confirmNewPassword: 'Passwords do not match' })
    if (!confirmNewPassword || newPassword === confirmNewPassword)
      setError({ confirmNewPassword: '' })
  }, [newPassword, confirmNewPassword])

  return (
    <main>
      <div className="main-container auth-container">
        <div className="login-container">
          <div className="login-box">
            <img
              src="/android-chrome-512x512.png"
              alt="imagee"
              style={{ width: '80px', height: '80px', margin: 'auto', marginBottom: '1.5rem' }}
            />
            <h1 className="password-haeder">Change Password</h1>
            {/* <label className="password-label">Current Password</label> */}
            <AuthInputBox
              placeholder="Current Password"
              errorMessage={error.currentPassword}
              value={currentPassword}
              handleChange={handleCurrentPasswordChange}
            />
            {/* <label className="password-label">New Password</label> */}
            <AuthInputBox
              placeholder="New Password"
              errorMessage={error.newPassword}
              value={newPassword}
              handleChange={handleNewPasswordChange}
            />
            <AuthInputBox
              placeholder="Confirm Password"
              errorMessage={error.confirmNewPassword}
              value={confirmNewPassword}
              handleChange={handleConfirmNewPasswordChange}
            />
            <div className="auth-button-box">
              {successMessage && <p className="password-change-success">{successMessage}</p>}
              <AnimatedButton
                disabled={
                  !currentPassword ||
                  !newPassword ||
                  !confirmNewPassword ||
                  newPassword !== confirmNewPassword
                }
                onClick={() =>
                  changePassword(currentPassword, newPassword, setError, setSuccessMessage, {
                    setCurrentPassword,
                    setNewPassword,
                    setConfirmNewPassword,
                  })
                }
                style={{
                  width: '50%',
                  justifyContent: 'center',
                  margin: 'auto',
                  height: '3rem',
                }}
              >
                Change Password
              </AnimatedButton>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

/**
 *
 * @param {String} currentPassword
 * @param {String} newPassword
 * @param {Function} setError
 * @param {Function} setSuccessMessage
 * @param {Object} setPassword
 * @param {Function} setPassword.setCurrentPassword
 * @param {Function} setPassword.setNewPassword
 * @param {Function} setPassword.setConfirmNewPassword
 *
 */
const changePassword = async (
  currentPassword,
  newPassword,
  setError,
  setSuccessMessage,
  setPassword,
) => {
  if (!currentPassword) console.error('Current password field is required')
  if (!newPassword) console.error('New password field is required')

  // Check if current password is correct
  try {
    await api.auth.changePassword(currentPassword, newPassword)
    setError({ currentPassword: '', newPassword: '', confirmNewPassword: '' })
    setSuccessMessage('Password changed successfully')
    setPassword.setCurrentPassword('')
    setPassword.setConfirmNewPassword('')
    setPassword.setNewPassword('')
  } catch (error) {
    console.error('e: ', error)
    setError({ currentPassword: error })
  }
}

export default ChangePassword
