import React from 'react'

/**
 *
 * @param {Object} componentProps
 * @param {String} componentProps.placeholder
 * @param {String} [componentProps.errorMessage]
 * @param {String} componentProps.value
 * @param {Function} componentProps.handleChange
 * @param {Boolean} [componentProps.isPassword]
 * @returns
 */
const AuthInputBox = ({
  placeholder,
  errorMessage = '',
  value,
  handleChange,
  isPassword = true,
}) => {
  return (
    <div
      className={`password-input-box ${
        errorMessage ? 'password-input-error' : 'password-input-regular'
      }`}
    >
      <input
        className="password-input"
        type={isPassword ? 'password' : 'text'}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
      {errorMessage ? <p className="password-input-error-message">{errorMessage}</p> : null}
    </div>
  )
}

export default AuthInputBox
