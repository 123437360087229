import prefix from './prefix'
import request from './request'
import { signout } from './auth'

export const me = function () {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/ambassador/me').end((err, res) => {
      if (err) return reject(err)
      if (res?.body?.status === 401) return signout()
      if (res?.body?.status !== 200) return reject(res?.body?.message)

      return resolve(res.body.data)
    })
  })
}

export const claimBalance = async () => {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/ambassador/claim-balance').end((err, res) => {
      if (err) return reject(err)
      if (res?.body?.status === 401) return signout()
      if (res?.body?.status !== 200) return reject(res?.body?.message)

      resolve(res)
    })
  })
}
