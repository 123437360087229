import React, { useState, useEffect } from 'react'
import AnimatedButton from '../components/AnimatedButton/AnimatedButton'
import AuthInputBox from '../components/AuthInputBox'
import api from '../api'

import { useNavigate } from 'react-router-dom'

const PasswordReset = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [showPasswordReset, setShowPasswordReset] = useState(false)
  const [passwordResetCode, setPasswordResetCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [error, setError] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    email: '',
  })

  const handleEmailChange = e => {
    setEmail(e.target.value)
  }

  const handlePasswordResetCodeChange = e => {
    setPasswordResetCode(e.target.value)
  }
  const handleNewPasswordChange = e => {
    setNewPassword(e.target.value)
  }

  const handleConfirmNewPasswordChange = e => {
    setConfirmNewPassword(e.target.value)
  }

  useEffect(() => {
    if (confirmNewPassword && newPassword !== confirmNewPassword)
      setError({ confirmNewPassword: 'Passwords do not match' })
    if (!confirmNewPassword || newPassword === confirmNewPassword)
      setError({ confirmNewPassword: '' })
  }, [newPassword, confirmNewPassword])

  return (
    <main>
      <div className="main-container auth-container">
        <div className="login-container">
          <div className="login-box">
            <img
              src="/android-chrome-512x512.png"
              alt="imagee"
              style={{ width: '80px', height: '80px', margin: 'auto', marginBottom: '1.5rem' }}
            />
            {showPasswordReset ? (
              <>
                <h1 className="password-haeder">Password Reset</h1>
                <AuthInputBox
                  placeholder="Password Reset Code"
                  errorMessage={error.currentPassword}
                  value={passwordResetCode}
                  handleChange={handlePasswordResetCodeChange}
                  isPassword={false}
                />
                {/* <label className="password-label">New Password</label> */}
                <AuthInputBox
                  placeholder="New Password"
                  errorMessage={error.newPassword}
                  value={newPassword}
                  handleChange={handleNewPasswordChange}
                />
                <AuthInputBox
                  placeholder="Confirm Password"
                  errorMessage={error.confirmNewPassword}
                  value={confirmNewPassword}
                  handleChange={handleConfirmNewPasswordChange}
                />
                <div className="auth-button-box">
                  {successMessage && <p className="password-change-success">{successMessage}</p>}
                  <AnimatedButton
                    disabled={
                      !passwordResetCode ||
                      !newPassword ||
                      !confirmNewPassword ||
                      newPassword !== confirmNewPassword
                    }
                    onClick={() =>
                      changePassword(
                        email,
                        passwordResetCode,
                        newPassword,
                        setError,
                        setSuccessMessage,
                        {
                          setPasswordResetCode,
                          setNewPassword,
                          setConfirmNewPassword,
                        },
                        navigate,
                      )
                    }
                    style={{
                      width: '50%',
                      justifyContent: 'center',
                      margin: 'auto',
                      height: '3rem',
                    }}
                  >
                    Change Password
                  </AnimatedButton>
                </div>
              </>
            ) : (
              <>
                <h1 className="password-haeder"> Get Reset Code</h1>
                <AuthInputBox
                  placeholder="Email"
                  errorMessage={error.email}
                  value={email}
                  handleChange={handleEmailChange}
                  isPassword={false}
                />
                <div className="auth-button-box">
                  {successMessage && <p className="password-change-success">{successMessage}</p>}
                  <AnimatedButton
                    disabled={!email}
                    onClick={() => sendPasswordResetCode(email, setShowPasswordReset, setError)}
                    style={{
                      width: '50%',
                      justifyContent: 'center',
                      margin: 'auto',
                      height: '3rem',
                    }}
                  >
                    Get Code
                  </AnimatedButton>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  )
}

const sendPasswordResetCode = async (email, setShowPasswordReset, setError) => {
  if (!email) console.error('Email field is required')

  try {
    await api.auth.inititatePasswordReset(email)
    setShowPasswordReset(true)
  } catch (error) {
    console.error('e: ', error)
    setError({ email: error })
  }
}

/**
 *
 * @param {String} email
 * @param {String} passwordResetCode
 * @param {String} newPassword
 * @param {Function} setError
 * @param {Function} setSuccessMessage
 * @param {Object} setPassword
 * @param {Function} setPassword.setPasswordResetCode
 * @param {Function} setPassword.setNewPassword
 * @param {Function} setPassword.setConfirmNewPassword
 * @param {Function} navigate
 *
 */
const changePassword = async (
  email,
  passwordResetCode,
  newPassword,
  setError,
  setSuccessMessage,
  setPassword,
  navigate,
) => {
  if (!email) console.error('Email field is required')
  if (!passwordResetCode) console.error('Reset code field is required')
  if (!newPassword) console.error('New password field is required')

  // Check if current password is correct
  try {
    await api.auth.resetPassword(email, passwordResetCode, newPassword)
    setError({ currentPassword: '', newPassword: '', confirmNewPassword: '' })
    setSuccessMessage('Password reset successfully')
    setPassword.setPasswordResetCode('')
    setPassword.setConfirmNewPassword('')
    setPassword.setNewPassword('')
    navigate('/login')
  } catch (error) {
    console.error('e: ', error)
    setError({ currentPassword: error })
  }
}

export default PasswordReset
