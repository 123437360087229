import React, { useState, useEffect } from 'react'
import Progressbar from './Progressbar/Progressbar'
import AnimatedButton from './AnimatedButton/AnimatedButton'
import _ from 'lodash'
import LinkBox from './link-box/LinkBox'
import InfoBox from './info-box/InfoBox'
import { useMediaQuery } from 'react-responsive'

/**
 *
 * @param {Object} params
 * @param {Object} params.survey
 * @param {Function} params.joinSurvey
 * @param {Object} params.user
 * @param {Function} params.setModalState
 * @param {String} params.type [available, fielding, closed]
 * @returns
 */
export const SurveyCard = ({ survey, joinSurvey, user, setModalState, type }) => {
  const [surveyLink, setSurveyLink] = useState('')
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1224 })
  const isMobileOrTablet = useMediaQuery({ maxWidth: 1224 })

  const progress = {
    completed: survey?.incentiveData?.incentiveResponses || 1,
    limit: survey?.incentiveData?.responseLimit || 100,
  }

  useEffect(() => {
    if (survey) {
      let surveyLink = ''

      if (survey?.incentiveData?.isDbIncentiveProject) {
        surveyLink = `https://survey.collegepulse.com/jfe/form/${survey.id}?referralCode=${user._q_panelist_id}&institution_unitid=${user.institution_unitid}&growthChannel=ambassador`
      } else {
        surveyLink = `https://survey.collegepulse.com/jfe/form/${survey.id}?ambassadorCode=${user._q_panelist_id}&institution_unitid=${user.institution_unitid}&growthChannel=ambassador`
      }

      setSurveyLink(encodeURI(surveyLink))
    }
  }, [survey, user._q_panelist_id, user.institution_unitid])

  return (
    survey && (
      <div className="card mb-5">
        <div className="card-content pb-0" style={{ display: 'flex' }}>
          <div className="survey-card-img-box">
            <img
              src={survey?.imgSrc || '/assets/placeholder_survey.png'}
              className={isMobileOrTablet ? '' : 'mr-5'}
              alt="survey card"
              style={{ width: 250, objectFit: 'contain', objectPosition: 'top' }}
            />
            {isTablet && (
              <div>
                {type === 'fielding' ? (
                  <FieldingSurveyButtons
                    survey={survey}
                    isMobileOrTablet={isMobileOrTablet}
                    setModalState={setModalState}
                    progress={progress}
                    surveyLink={surveyLink}
                  />
                ) : type === 'available' ? (
                  <AvailableSurveyButtons
                    surveyId={survey.id}
                    isMobileOrTablet={isMobileOrTablet}
                    joinSurvey={joinSurvey}
                  />
                ) : null}
              </div>
            )}
          </div>
          <div className="content border-green is-flex-grow-1">
            <h4 className="mb-1">{survey.name}</h4>
            <hr className="mb-2 mt-2" />
            {type === 'fielding' ? (
              <FieldingSurveyDescription survey={survey} user={user} />
            ) : type === 'available' ? (
              <AvailableSurveyDescription survey={survey} />
            ) : type === 'closed' ? (
              <ClosedSurveyDescription survey={survey} user={user} />
            ) : null}
          </div>
          {!isTablet && (
            <div>
              {type === 'fielding' ? (
                <FieldingSurveyButtons
                  survey={survey}
                  isMobileOrTablet={isMobileOrTablet}
                  setModalState={setModalState}
                  progress={progress}
                  surveyLink={surveyLink}
                />
              ) : type === 'available' ? (
                <AvailableSurveyButtons
                  surveyId={survey.id}
                  isMobileOrTablet={isMobileOrTablet}
                  joinSurvey={joinSurvey}
                />
              ) : null}
            </div>
          )}
        </div>
        {progress?.completed && (
          <div className="survey-progress-box pl-5 pr-5 pb-5 flex flex-column">
            <p>Survey Progress:</p>
            <Progressbar
              currentNumber={progress.completed}
              maximumNumber={progress.limit}
              config={{ progressBarHeight: '1.4rem' }}
            />
          </div>
        )}
      </div>
    )
  )
}

const FieldingSurveyDescription = ({ survey, user }) => {
  return (
    <>
      <div className="is-flex is-justify-content-space-between">
        <div>Income Per Complete:</div>
        <div>${survey.incentiveData.ambassadorIncentiveAmount}</div>
      </div>
      <div className="is-flex is-justify-content-space-between">
        <div>Responses you've fielded:</div>
        <div>
          {
            user.ambassadorConfig.surveys.find(s => s._q_survey_id === survey.id)?.respondents
              .length
          }
        </div>
      </div>
      <div className="is-flex is-justify-content-space-between">
        <div>Earned from this survey:</div>
        <div>
          $
          {_.sumBy(
            user.ambassadorConfig.surveys.find(s => s._q_survey_id === survey.id)?.respondents,
            'ambassadorIncentiveAmount',
          )}
        </div>
      </div>
    </>
  )
}

const FieldingSurveyButtons = ({
  survey,
  isMobileOrTablet,
  setModalState,
  progress,
  surveyLink,
}) => {
  return (
    <div
      style={{ justifyContent: 'space-between' }}
      className={`survey-card-button-box survey-card-button-box-multiple ${
        isMobileOrTablet ? '' : 'pl-5'
      }`}
    >
      <AnimatedButton
        onClick={() => {
          setModalState({
            open: true,
            component: () => {
              return <InfoBox survey={survey} progress={progress} />
            },
            buttons: null,
            header: 'Survey Information',
          })
        }}
        className="survey-card-multiple-buttons"
        style={{ justifyContent: 'center', fontWeight: '600' }}
      >
        {isMobileOrTablet ? 'Survey Info' : 'Survey Information'}
      </AnimatedButton>
      <AnimatedButton
        className="survey-card-multiple-buttons"
        onClick={() => {
          setModalState({
            open: true,
            component: () => {
              return (
                <div className="content">
                  <p>Copy this link, and share it with your fellow classmates!</p>
                  <pre
                    dangerouslySetInnerHTML={{
                      __html: surveyLink.replaceAll(' ', '').replaceAll('\n', ''),
                    }}
                  ></pre>
                  <hr />
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <LinkBox surveyLink={surveyLink} />
                    <AnimatedButton
                      style={{ marginLeft: '1rem', fontWeight: '600' }}
                      onClick={() =>
                        setModalState({
                          open: false,
                          component: null,
                          buttons: null,
                          header: null,
                        })
                      }
                    >
                      Close
                    </AnimatedButton>
                  </div>
                </div>
              )
            },
            header: 'Survey Link',
          })
        }}
        style={{ justifyContent: 'center', fontWeight: '600' }}
      >
        Get Link
      </AnimatedButton>
    </div>
  )
}

const AvailableSurveyDescription = ({ survey }) => {
  return (
    <>
      <div className="is-flex is-justify-content-space-between">
        <div>Income Per Complete:</div>
        <div>${survey.incentiveData.ambassadorIncentiveAmount}</div>
      </div>
    </>
  )
}

const AvailableSurveyButtons = ({ isMobileOrTablet, joinSurvey, surveyId }) => {
  return (
    <div className={`survey-card-button-box ${isMobileOrTablet ? '' : 'pl-5'}`}>
      <AnimatedButton
        className={'survey-card-single-button'}
        onClick={() => {
          joinSurvey(surveyId)
        }}
        style={{ justifyContent: 'center', fontWeight: '600' }}
      >
        Share Survey
      </AnimatedButton>
    </div>
  )
}

const ClosedSurveyDescription = ({ survey, user }) => {
  return (
    <>
      <div className="is-flex is-justify-content-space-between">
        <div>Income Per Complete:</div>
        <div>${survey.incentiveData.ambassadorIncentiveAmount}</div>
      </div>
      <div className="is-flex is-justify-content-space-between">
        <div>Responses you've fielded:</div>
        <div>
          {
            user.ambassadorConfig.surveys.find(s => s._q_survey_id === survey.id)?.respondents
              .length
          }
        </div>
      </div>
      <div className="is-flex is-justify-content-space-between">
        <div>Earned from this survey:</div>
        <div>
          $
          {_.sumBy(
            user.ambassadorConfig.surveys.find(s => s._q_survey_id === survey.id)?.respondents,
            'ambassadorIncentiveAmount',
          )}
        </div>
      </div>
    </>
  )
}

// export const ClosedSurvey = ({ survey, user }) => {
//   return (
//     <div className="card mb-5 pb-5">
//       <div className="card-content pb-0" style={{ display: 'flex' }}>
//         <img
//           src={survey?.imgSrc || '/assets/placeholder_survey.png'}
//           className="mr-5"
//           alt="survey card"
//           style={{ width: 250, objectFit: 'contain', objectPosition: 'top' }}
//         />
//         <div className="content border-green is-flex-grow-1">
//           <h4 className="mb-1">{survey.title}</h4>
//           <p className="mb-0">{survey.description}</p>
//           <hr className="mb-2 mt-2" />
//           <div className="is-flex is-justify-content-space-between">
//             <div>Income Per Complete:</div>
//             <div>${survey.referralConfig[user.institution._id].ambassadorIncentiveAmount}</div>
//           </div>
//           <div className="is-flex is-justify-content-space-between">
//             <div>Surveys you've fielded:</div>
//             <div>
//               {
//                 user.ambassadorConfig.surveys.find(s => s._q_survey_id === survey._id).respondents
//                   .length
//               }
//             </div>
//           </div>
//           <div className="is-flex is-justify-content-space-between">
//             <div>Earned from this survey:</div>
//             <div>
//               $
//               {_.sumBy(
//                 user.ambassadorConfig.surveys.find(s => s._q_survey_id === survey._id).respondents,
//                 'ambassadorIncentiveAmount',
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }
