import React, { useState, useEffect } from 'react'
import AnimatedButton from '../components/AnimatedButton/AnimatedButton'
import UserContext from '../lib/UserContext'
import api from '../api'
import AuthInputBox from '../components/AuthInputBox'

import { useNavigate } from 'react-router-dom'

const Login = ({ setUser }) => {
  const user = React.useContext(UserContext)
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginInitiated, setLoginInitiated] = useState(false)
  const [error, setError] = useState({
    email: '',
    password: '',
  })

  const handleEmailChange = e => {
    setEmail(e.target.value)
  }
  const handlePasswordChange = e => {
    setPassword(e.target.value)
  }

  useEffect(() => {
    if (user?.authenticated) {
      navigate('/dashboard')
    }
  }, [user, navigate])

  const navigateToPasswordReset = async () => {
    // await api
    navigate('/reset-password')
  }

  return (
    <main>
      <div className="main-container auth-container">
        <div className="login-back-arrow">
          <p onClick={() => navigate('/')}>←</p>
        </div>
        <div className="login-container">
          <div className="login-box">
            <img
              src="/android-chrome-512x512.png"
              alt="imagee"
              style={{ width: 80, margin: 'auto', marginBottom: '1.5rem' }}
            />
            <h1 className="password-haeder">Login to Ambassador Portal</h1>
            <AuthInputBox
              placeholder="Email"
              errorMessage={error.email}
              value={email}
              handleChange={handleEmailChange}
              isPassword={false}
            />
            <AuthInputBox
              placeholder="Password"
              errorMessage={error.password}
              value={password}
              handleChange={handlePasswordChange}
            />
            <div className="auth-button-box">
              <AnimatedButton
                disabled={loginInitiated}
                onClick={() => {
                  setLoginInitiated(true)
                  attepmtLogin(email, password, setUser, setError, setLoginInitiated)
                  return
                }}
                className={loginInitiated ? 'loading' : ''}
                style={{
                  height: '3rem',
                  margin: 'auto',
                  width: '50%',
                  justifyContent: 'center',
                }}
              >
                {loginInitiated ? 'Logging In...' : 'Login'}
              </AnimatedButton>
              {
                <p className="forgot-password-txt" onClick={() => navigateToPasswordReset()}>
                  Forgot your password?
                </p>
              }
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

/**
 *
 * @param {String} email
 * @param {String} password
 * @param {Function} setUser
 * @param {Function} setError
 * @param {Function} setLoginInitiated
 * @returns {Promise<void>}
 */
const attepmtLogin = async (email, password, setUser, setError, setLoginInitiated) => {
  if (!email) return console.error('Email field is required')
  if (!password) return console.error('Password field is required')
  if (!setUser) throw new Error('setUser function is required')

  try {
    const data = await api.auth.signin(email, password)
    if (data.user) {
      setUser({ ...data.user, authenticated: true })
    }
  } catch (e) {
    setLoginInitiated(false)
    if (e?.includes('email')) {
      setError({ email: e })
    } else {
      setError({ password: e })
    }
    console.error(e)
  }
}

export default Login
