// Routing
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

// Pages
import NavBar from './components/NavBar/NavBar'
import Landing from './pages/Landing'
import Login from './pages/Login'
import Home from './pages/dashboard/Home'
import Resources from './pages/dashboard/Resources'
import ResourcesMoreInfo from './pages/dashboard/ResourcesMoreInfo'
import ChangePassword from './pages/dashboard/ChangePassword'
import PasswordReset from './pages/PasswordReset'

//Context
import UserContext from './lib/UserContext'

//Internal Modules
import api from './api'
import storage from './lib/storage'

const App = () => {
  const userFromStroage = storage.get('user') || null
  const [user, setUser] = useState(userFromStroage)

  const updateUser = async () => {
    const user = await api.user.me()
    setUser({ ...user, authenticated: true })
    storage.set({ name: 'user', val: { ...user, authenticated: true } })
  }

  useEffect(() => {
    checkAuth(user, setUser)
  }, [user])

  return (
    <UserContext.Provider value={user}>
      <BrowserRouter>
        {user?.authenticated ? <NavBar /> : null}
        <Routes>
          <Route exact path="/" element={<Landing setUser={setUser} updateUser={updateUser} />} />
          <Route path="/login" element={<Login setUser={setUser} updateUser={updateUser} />} />
          <Route path="/reset-password" element={<PasswordReset setUser={setUser} />} />
          <Route
            exact
            path="/dashboard"
            element={
              <ProtectedRoute user={user}>
                <Home setUser={setUser} updateUser={updateUser} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/change-password"
            element={
              <ProtectedRoute user={user}>
                <ChangePassword />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/resources"
            element={
              <ProtectedRoute user={user}>
                <Resources />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/resources/more-info"
            element={
              <ProtectedRoute user={user}>
                <ResourcesMoreInfo />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  )
}

const ProtectedRoute = ({ user, children }) => {
  if (!user || _.isEmpty(user) || !user?.authenticated) {
    return <Navigate to="/" />
  }

  return children
}

/**
 *  Check if token is valid and user is logged in. Check token with the server.
 *  If token is invalid, log user out.
 *  If token is valid, and ambassadorConfig differs between curren user and DB user update user context.
 *
 *  @param {Object} curUser - Current user object
 *  @param {Callback} setUser - Set user context
 *
 * @returns {Promise<void>}
 */
const checkAuth = async (curUser, setUser) => {
  const storageToken = storage.get('token')
  if (storageToken && storageToken !== 'undefined') {
    try {
      const user = await api.user.me()

      if (!user?.ambassador) {
        api.auth.signout()
      }

      if (!_.isEqual(curUser.ambassadorConfig, user.ambassadorConfig)) {
        setUser({ ...user, authenticated: true })
        storage.set({ name: 'user', val: { ...user, authenticated: true } })
      }
    } catch (err) {
      console.error('checkAuth err', err)
      api.auth.signout()
    }
  }
}

export default App
