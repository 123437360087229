export const auth = require('./auth')
export const user = require('./user')
export const survey = require('./survey')

const api = {
  auth,
  user,
  survey,
}

export default api
