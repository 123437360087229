import React from 'react'
import { useMediaQuery } from 'react-responsive'

const InfoBox = ({ survey, progress: { completed, limit } }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const config = survey.incentiveData

  return (
    <div>
      <p>
        <strong>Survey Title: </strong>
        {survey.name}
      </p>
      <hr />
      {/* <p>
        <strong>Survey Description:</strong> {survey.description}
      </p> 
      <hr />*/}
      {/* <p>
        <strong>Survey created at:</strong> {new Date(survey.createdAt).toLocaleString()}
      </p>
      <hr /> */}
      {/* <p>
        <strong>Survey Image URL:</strong>{' '}
        <a href={survey.imgSrc} target="_blank" rel="noreferrer">
          {survey.imgSrc}
        </a>
      </p>
      <hr /> */}
      <p>
        <strong>Participants will get paid:</strong> ${config?.directIncentiveAmount} per complete
        <br />
        <strong>You will get paid:</strong> ${config?.ambassadorIncentiveAmount} per complete
      </p>
      <hr />
      {/* <p>
        <strong>Question length:</strong> {survey.questions.length}{' '}
        <em>+ Demographic questions for new users.</em>
      </p> 
      <hr />*/}
      <p>
        <strong>{`${
          config?.unitid === 'all' || isMobile
            ? 'Responses needed:'
            : 'Responses needed at your institution:'
        }`}</strong>{' '}
        {limit}
        <br />
        <strong>{`${
          config?.unitid === 'all' || isMobile
            ? 'Responses collected:'
            : 'Responses collected at your institution:'
        }`}</strong>
        {'  '}
        {completed}
        <br />
        <strong>{`${
          config?.unitid === 'all' || isMobile
            ? 'Responses remaining:'
            : 'Responses remaining at your school:'
        }`}</strong>
        {'  '}
        {limit - completed}
        <br />
        <strong>{`${isMobile ? 'Potential earnings:' : 'Potential earnings remaining:'}`}</strong>
        {'  '}${(limit - completed) * config.ambassadorIncentiveAmount}
      </p>
    </div>
  )
}

export default InfoBox
