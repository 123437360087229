import React from 'react'
import AnimatedButton from '../AnimatedButton/AnimatedButton'
import './AmbassadorPortfolioCard.scss'

const AmbassadorPortfolioCard = ({ style, className, avatarSrc, ambassador, cardButton }) => {
  return (
    <div className={`ambassador-card card has-background-light ${className}`} style={style}>
      <div className="card-content has-text-centered">
        {avatarSrc ? (
          <div className="card-image is-inline-block">
            <figure className="image is-96x96 is-1by1">
              <img
                className="avatar-img is-rounded has-background-primary"
                src={avatarSrc}
                alt="Avatar"
                style={{ objectFit: 'cover' }}
              />
            </figure>
          </div>
        ) : null}
        {ambassador && ambassador.metadata ? (
          <div className="media">
            <div className="media-content">
              <p className="ambassador-card-name">
                {ambassador.metadata.firstName} {ambassador.metadata.lastName}
              </p>
              <p>
                {ambassador?.metadata?.institution && (
                  <>
                    {ambassador.metadata.institution} <br />
                  </>
                )}

                {ambassador?.metadata?.createdAt ? (
                  <>
                    Active since{' '}
                    <span style={{ fontWeight: '600' }}>{ambassador.metadata.createdAt}</span>
                  </>
                ) : null}
              </p>
            </div>
          </div>
        ) : null}
        {ambassador ? renderDataTable(ambassador.data) : null}
        <div className="buttons-box">
          <AnimatedButton
            className="is-fullwidth mt-4"
            disabled={cardButton.disabled}
            onClick={cardButton.onClick}
            style={{ fontSize: '1rem', fontWeight: 'bold', justifyContent: 'center' }}
          >
            {cardButton.text}
          </AnimatedButton>
        </div>
      </div>
    </div>
  )
}

const renderDataTable = data => {
  // If object is undefined/null or empty
  if (!data || Object.keys(data).length === 0) return null

  return (
    <ul className="custom-table container has-background-white">
      {Object.keys(data).map(key => {
        return (
          <li className="listRow" key={key}>
            <p className="left">{key}</p>
            {/* <div className="middle">
              <div className="dots" />
            </div> */}
            <p className="right" style={{ fontWeight: 'bold' }}>
              {data[key]}
            </p>
          </li>
        )
      })}
    </ul>
  )
}

const metadata = {
  firstName: '',
  lastName: '',
  institution: '',
  createdAt: '',
}

const data = {
  Title1: 'value1',
  Title2: 'value2',
}

AmbassadorPortfolioCard.defaultProps = {
  className: '',
  style: { width: '280px' },
  avatarSrc: '',
  ambassador: {
    metadata: metadata,
    data: data,
  },
  metadata: {
    firstName: '',
    lastName: '',
    institution: '',
    createdAt: '',
  },
  data: {
    Title1: 'value1',
    Title2: 'value2',
  },
  cardButton: {
    text: 'MyButton',
    disabled: false,
    onClick: () => {
      console.log('Button clicked!')
    },
  },
}

export default AmbassadorPortfolioCard
